import request from '../utils/request'
import baseUrl from './baseUrl'
export const checkMemberDay = (data) => request({
  url: baseUrl + '/userDay/select',
  method: 'POST',
  data
})
export const editMemberDay = (data) => request({
  url: baseUrl + '/userDay/modify',
  method: 'POST',
  data
})
export const memberProductList = (data) => request({
  url: baseUrl + '/userDayRight/selectForBack',
  method: 'POST',
  data
})
export const memberProductAdd = (data) => request({
  url: baseUrl + '/userDayRight/add',
  method: 'POST',
  data
})
export const memberProductDel = (data) => request({
  url: baseUrl + '/userDayRight/deleteById',
  method: 'POST',
  data
})
export const memberProductEdit = (data) => request({
  url: baseUrl + '/userDayRight/modify',
  method: 'POST',
  data
})

// 生日礼遇
export const birthdayProductList = (data) => request({
  url: baseUrl + '/userBirthdayRight/selectForBack',
  method: 'POST',
  data
})
export const birthdayProductAdd = (data) => request({
  url: baseUrl + '/userBirthdayRight/add',
  method: 'POST',
  data
})
export const birthdayProductDel = (data) => request({
  url: baseUrl + '/userBirthdayRight/deleteById',
  method: 'POST',
  data
})
export const birthdayProductEdit = (data) => request({
  url: baseUrl + '/userBirthdayRight/modify',
  method: 'POST',
  data
})
//设置会员日期信息
export const userTime = (data) => request({
  url: baseUrl + '/userDay/modify',
  method: 'POST',
  data
})
//查询会员日配置

export const selectuserTime = (data) => request({
  url: baseUrl + '/userDay/select',
  method: 'POST',
  data
})